import { useCallback, useState, useMemo, useEffect } from 'react';
import axiosInstance from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'src/components/snackbar';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
// assets
// components
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
// import PasswordIcon from 'src/assets/icons/password-icon';
import PasswordIcon from '@mui/icons-material/Password';
import SmsIcon from '@mui/icons-material/Sms';
import { useSettingsContext } from 'src/components/settings';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import qrcodeimage from '../../assets/img/chart.png';

// ----------------------------------------------------------------------

export default function ModernMFAView() {
    const [Error, setError] = useState(null);
    const [authData, setAuthdata] = useState([]);
    const [smsmessage, setSMSMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('en-US');
    const [isChecked, setIsChecked] = useState('');
    // const [mfadata1, setData1] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const settings = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const mfaData = useMemo(() => location.state?.AuthData || {}, [location.state]);
    console.log('mfa', mfaData);
    // const mfaData1 = useMemo(() => location.state?.mfadata || {}, [location.state]);
    // console.log('mfa1', mfaData1);
    // const mfaData2 = useMemo(() => location.state?.userdata || {}, [location.state]);
    // console.log('mfa2', mfaData2);
    const mfaData4 = useMemo(() => location.state?.apiData3 || {}, [location.state]);
    console.log('mfa4', mfaData4);

    const mfaData3 = useMemo(() => location.state?.authmfadata || {}, [location.state]);
    console.log('mfaData3', mfaData3);

    const data2 = localStorage.getItem('loginresponse');
    const mfadata1 = JSON.parse(data2);

    const data3 = localStorage.getItem('logindata');
    const mfaData2 = JSON.parse(data3);

    useEffect(() => {
        // setData(mfaData);
    }, [mfaData]);

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const langcodes = [
        { label: 'English (United States)', value: 'en-US' },
        { label: 'English (United Kingdom)', value: 'en-GB' },
        { label: 'Spanish (Latin America)', value: 'es-419' },
        { label: 'Spanish (Spain)', value: 'es-ES' },
        { label: 'German (Germany)', value: 'de-DE' },
        { label: 'French (Canada)', value: 'fr-CA' },
        { label: 'French (France)', value: 'fr-FR' },
        { label: 'Italian (Italy)', value: 'it-IT' },
        { label: 'Japanese (Japan)', value: 'ja-JP' },
        { label: 'Portuguese (Brazil)', value: 'pt-BR' },
        { label: 'Korean (South Korea)', value: 'kr-KR' },
        { label: 'Chinese (Simplified)', value: 'zh-CN' },
        { label: 'Chinese (Traditional)', value: 'zh-TW' },
    ];

    const defaultValues = {
        code: '',
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const authmfa = async (formData) => {
                try {
                    const authmfadata = {
                        access_token:
                            mfadata1?.AuthenticationResult?.AccessToken || mfaData3?.access_token,
                        session: mfaData?.Session,
                        user_code: formData.code,
                    };
                    console.log('authmfadata', authmfadata);
                    const response = await axiosInstance.post('/user/verify_mfa', authmfadata);
                    console.log('API response:', response.data);

                    if (response.data?.Message === 'MFA Verified Successfully!') {
                        mfasetup();
                        setTimeout(() => {
                            enqueueSnackbar(response.data?.Message);
                        }, 500);
                    } else {
                        const UserError = response.data?.Message || response.data.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        const UserError = error.response.data.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    } else {
                        const UserError = 'Something Went Wrong, Please try again later!';
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                }
            };

            const smsmfa = async (formData) => {
                try {
                    const smsmfadata = {
                        phone_number: phoneNumber,
                        otp: formData.code,
                    };
                    console.log('smsmfadata', smsmfadata);
                    const response = await axiosInstance.post('/user/verify_sms', smsmfadata);
                    console.log('API response:', response.data);
                    const SMSMsg = response.data?.Message;
                    if (SMSMsg === 'Phone Number Verified Successfully!') {
                        mfasetup();
                        setTimeout(() => {
                            enqueueSnackbar('Phone Number Verified Successfully!');
                        }, 500);
                    } else {
                        const UserError = response.data.data?.Message || response.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        const UserError = error.response.data.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    } else {
                        const UserError = 'Something Went Wrong, Please try again later!';
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                }
            };

            const mfasetup = async () => {
                try {
                    const mfasetupdata = {
                        access_token:
                            mfadata1?.AuthenticationResult?.AccessToken || mfaData3?.access_token,
                        mfa_type: mfaData3.mfa_type,
                        user_name: mfadata1?.user_name || mfaData3?.user_name,
                        user_email: mfaData2?.user_email,
                    };
                    console.log('authmfa', mfasetupdata);
                    const response = await axiosInstance.post('/user/mfa_setup', mfasetupdata);
                    console.log('API response:', response.data);
                    const AuthData = response.data;
                    const MFAMessage = response.data?.Message;
                    if (MFAMessage === 'MFA Enabled Successfully') {
                        setAuthdata(AuthData);
                        navigate('/authentication/auth-login', {
                            state: { mfaData2, mfaData4 },
                        });
                        setTimeout(() => {
                            enqueueSnackbar('MFA Verified Successfully!');
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        const UserError = error.response.data.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    } else {
                        const UserError = 'Something Went Wrong, Please try again later!';
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                }
            };

            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);

                if (mfaData3.mfa_type === 'authapps') {
                    console.log('1');
                    await authmfa(data);
                } else if (
                    mfaData3.mfa_type === 'sms' &&
                    mfaData2.is_phone_number_verified === false
                ) {
                    console.log('2');
                    await smsmfa(data);
                } else if (
                    mfaData3.mfa_type === 'sms' &&
                    mfaData2.is_phone_number_verified === true
                ) {
                    console.log('3');
                    await mfasetup();
                }
            } catch (error) {
                console.error(error);
            }
        },
        [mfadata1, navigate, enqueueSnackbar, mfaData3, phoneNumber, mfaData2, mfaData4, mfaData]
    );

    const smssetup = async () => {
        setLoading(true);
        try {
            const smssetupdata = {
                phone_number: phoneNumber,
                lang_code: selectedLanguage,
            };
            console.log('smssetupdata', smssetupdata);
            const response = await axiosInstance.post('/user/send_sms', smssetupdata);
            console.log('API response:', response.data);
            const SMSMessage = response.data?.Message;
            if (SMSMessage === 'Verification Code Sent Successfully!') {
                setSMSMessage(SMSMessage);
                setTimeout(() => {
                    enqueueSnackbar('Verification Code Sent Successfully!');
                }, 500);
            } else {
                const UserError = response.data?.Message;
                setError(UserError);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                const UserError = error.response.data.Message;
                setError(UserError);
                alert(UserError);
            } else {
                const UserError = 'Something Went Wrong, Please try again later!';
                setError(UserError);
                alert(UserError);
            }
        }
        setLoading(false);
    };
    const handleLangChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const qrCodeLink =
        'https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=%7B%22android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME%22%3A%22com.google.android.apps.work.clouddpc%2F.receivers.CloudDeviceAdminReceiver%22%2C%22android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM%22%3A%22I5YvS0O5hXY46mb01BlRjq4oJJGs2kuUcHvVkAPEXlg%22%2C%22android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION%22%3A%22https%3A%2F%2Fplay.google.com%2Fmanaged%2FdownloadManagingApp%3Fidentifier%3Dsetup%22%2C%22android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE%22%3A%7B%22com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN%22%3A%22RWPHCCUHKFEWWTBPDJLC%22%7D%7D';

    const rendermfa = (
        <>
            <PasswordIcon fontSize="large" color="primary" />

            <Stack spacing={2} sx={{ my: 1, alignItems: 'center' }}>
                <Typography variant="h4">Authenticator App Verification</Typography>
                {/* <CardMedia
          component="img"
          alt="QR Code"
          style={{
            maxWidth: '40%',
            height: 'auto',
          }}
          image={
            mfaData.image_base64 ? `data:image/png;base64,${mfaData.image_base64}` : qrCodeLink
          }
        /> */}
                {/* <Box
            component="img"
            src={`data:image/png;base64,${mfaData.image_base64}`}
            sx={{
              maxWidth: '40%',
              filter: settings.themeMode === 'light' ? 'invert(0)' : 'invert(1)',
              mixBlendMode: settings.themeMode === 'light' ? 'normal' : 'color-dodge',
              aspectRatio: '1/1',
            }}
          /> */}
                {mfaData.image_base64 ? (
                    <Box
                        component="img"
                        src={`data:image/png;base64,${mfaData.image_base64}`}
                        sx={{
                            maxWidth: '40%',
                            filter: settings.themeMode === 'light' ? 'invert(0)' : 'invert(1)',
                            mixBlendMode: settings.themeMode === 'light' ? 'normal' : 'color-dodge',
                            aspectRatio: '1/1',
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '60%',
                            aspectRatio: '1/1',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component="img"
                            src={qrcodeimage}
                            sx={{
                                width: '60%',
                                filter: settings.themeMode === 'light' ? 'invert(0)' : 'invert(1)',
                                mixBlendMode:
                                    settings.themeMode === 'light' ? 'normal' : 'color-dodge',
                                aspectRatio: '1/1',
                                position: 'absolute',
                            }}
                        />
                        <Box
                            sx={{
                                width: '59%',
                                aspectRatio: '1/1',
                                background: 'rgba(217, 217, 217, 0.50)',
                                filter: 'blur(5px)',
                                backdropFilter: 'blur(2px)',
                                position: 'absolute',
                            }}
                        />
                        <CircularProgress
                            color="primary"
                            size={20}
                            sx={{ position: 'absolute', color: 'primary' }}
                        />
                    </Box>
                )}
                {/* <QRCode
          value={
            mfaData.image_base64 ? `data:image/png;base64,${mfaData.image_base64}` : qrCodeLink
          }
          logoImage={qrcodelogo}
          bgColor="#ffff"
        /> */}
                {/* <Box sx={{ width: { xs: '90%', md: '75%' } }}>
          {mfaData.image_base64 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              aspectRatio: '1/1',
              position: 'relative',
            }}
          >
            <QRCode
              value="../../assets/img/chart.png"
              logoImage={qrcodelogo}
              bgColor="#ffff"
              logoWidth="100%"
              logoHeight="100%"
              logoPadding={1}
              qrStyle="squares"
            />
          </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                aspectRatio: '1/1',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                src={qrCodeLink}
                sx={{
                  width: '100%',
                  filter: settings.themeMode === 'light' ? 'invert(0)' : 'invert(1)',
                  mixBlendMode: settings.themeMode === 'light' ? 'normal' : 'color-dodge',
                  aspectRatio: '1/1',
                  m: 3,
                  position: 'absolute',
                }}
              />
              <Box
                sx={{
                  width: '89%',
                  aspectRatio: '1/1',
                  background: 'rgba(217, 217, 217, 0.50)',
                  filter: 'blur(10px)',
                  backdropFilter: 'blur(2px)',
                  position: 'absolute',
                }}
              />
              <CircularProgress color="primary" size={20} sx={{ position: 'absolute' }} />
            </Box>
          )}
        </Box> */}
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Please scan the above QR Code using Google or Microsoft Authenticator Apps.
                </Typography>
                <Typography
                    variant="caption"
                    color="primary"
                    sx={{ maxWidth: '80%', wordWrap: 'break-word' }}
                >
                    {mfaData.SecretCode}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    If you having trouble using the QR code, select manual entry on your
                    Authenticator App
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Please enter the 6-digit MFA Code below to verify.
                </Typography>
                
                {/* <RHFTextField
                    name="email"
                    label="Email address"
                    value={mfadata1?.email || mfaData2?.email || mfaData3?.user_email}
                    type="email"
                    // disabled
                    placeholder="example@gmail.com"
                    InputLabelProps={{ shrink: true }}
                /> */}
                <RHFCode name="code" />
                <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                        color="primary"
                        value={isChecked ? 'remember' : 'not_remembered'}
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <Typography variant="body2">Do you like to confirm the MFA Device?</Typography>
                </Stack>
                {isChecked ? (
                    <RHFTextField
                        name="devicename"
                        label="Device Name"
                        // value={mfadata1.email || mfaData2.user_email}
                    />
                ) : null}
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Enable MFA
                </LoadingButton>
                <Link
                    component={RouterLink}
                    href={paths.authentication.authlogin}
                    color="inherit"
                    variant="subtitle2"
                    sx={{
                        alignItems: 'center',
                        display: 'inline-flex',
                    }}
                >
                    Return to sign in
                </Link>
            </Stack>
        </>
    );

    const isApplyButtonEnabled = selectedLanguage && phoneNumber;

    const rendersms = (
        <>
            <SmsIcon fontSize="large" color="primary" />
            <Typography variant="h4">SMS Verification</Typography>

            {mfaData4.is_phone_number_verified || mfaData2.is_phone_number_verified === true ? (
                <>
                    <Typography variant="h4">Mobile Number Verified</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Your mobile phone number is already verified, please enable SMS MFA for
                        authentication
                    </Typography>
                </>
            ) : (
                <Stack spacing={2} sx={{ my: 1, alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Verify Your Mobile Number for SMS MFA
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Enter your mobile phone number with the country code, and we will send you a
                        verification code.
                    </Typography>

                    <Stack direction="row" spacing={2} alignItems="center">
                        <RHFTextField
                            name="phonenumber"
                            label="Phone number"
                            value={phoneNumber || mfaData2?.phonenumber}
                            placeholder="+910123456789"
                            onChange={handlePhoneNumberChange}
                            sx={{ width: '150px' }}
                        />
                        <FormControl>
                            <InputLabel id="language-label">Select SMS Language</InputLabel>
                            <Select
                                labelId="language-label"
                                id="language-select"
                                value={selectedLanguage}
                                onChange={handleLangChange}
                                fullWidth
                            >
                                {langcodes.map((language) => (
                                    <MenuItem key={language.value} value={language.value}>
                                        {language.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={smssetup}
                        disabled={!isApplyButtonEnabled}
                    >
                        {loading ? <CircularProgress size={20} /> : 'Verify'}
                    </Button>
                </Stack>
            )}

            {smsmessage === 'Verification Code Sent Successfully!' &&
            (mfaData4?.is_phone_number_verified === false ||
                mfaData2?.is_phone_number_verified === false) ? (
                <>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Please enter the 6-digit Verification Code below to verify.
                    </Typography>
                    <RHFCode name="code" />
                </>
            ) : null}
            <Stack direction="column" spacing={2} mt={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                        color="primary"
                        value={isChecked ? 'remember' : 'not_remembered'}
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <Typography variant="body2">
                        {isChecked ? 'MFA Device is enabled' : 'MFA Device is disabled'}
                    </Typography>
                </Stack>
                {isChecked ? <RHFTextField name="devicename" label="Device Name" /> : null}
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Enable MFA
                </LoadingButton>

                <Link
                    component={RouterLink}
                    href={paths.authentication.authlogin}
                    color="inherit"
                    variant="subtitle2"
                    sx={{
                        alignItems: 'flex-end',
                        display: 'inline-flex',
                    }}
                    underline="always"
                >
                    &lt; Return to sign in?
                </Link>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div>{Error && <Alert severity="error">{Error}</Alert>}</div>
            {mfaData3.mfa_type === 'sms' ? rendersms : rendermfa}
        </FormProvider>
    );
}
