import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// assets
import { SentIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFCode } from 'src/components/hook-form';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'src/components/snackbar';

// ----------------------------------------------------------------------

export default function ModernNewPasswordView() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const password = useBoolean();

    const NewPasswordSchema = Yup.object().shape({
        code: Yup.string()
            .min(6, 'Code must be at least 6 characters')
            .required('Code is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    const forgotemail = localStorage.getItem('forgotemail');

    const defaultValues = {
        code: '',
        password: '',
        confirmPassword: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const confpass = async (formData) => {
                try {
                    const passwordData = {
                        user_email: forgotemail,
                        conf_code: formData.code,
                        new_password: formData.password,
                    };
                    console.log('passwordData', passwordData);
                    const response = await axiosInstance.post('/user/conf_pass', passwordData);
                    console.log('API response:', response.data);
                    const PassMsg = response.data?.Message;
                    if (PassMsg === 'Password Changed Successfully!') {
                        navigate('/authentication/auth-login');
                        setTimeout(() => {
                            enqueueSnackbar('Password Changed Successfully!');
                        }, 1000);
                    } else {
                        const UserError = response.data.data?.Message || response.data.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                        setTimeout(() => {
                            navigate('/authentication/auth-forgot-password');
                        }, 2000);
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                        setTimeout(() => {
                            navigate('/authentication/auth-forgot-password');
                        }, 2000);
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                        setTimeout(() => {
                            navigate('/authentication/auth-forgot-password');
                        }, 2000);
                    }
                }
            };
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);
                if (data.email && data.code && data.password) {
                    await confpass(data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        [enqueueSnackbar, navigate, forgotemail]
    );

    const forgotpass = async () => {
        setLoading(true);
        try {
            const passdata = {
                user_email: forgotemail,
            };
            console.log('passdata', passdata);
            const response = await axiosInstance.post('/user/forgot_pass', passdata);
            console.log('API response:', response.data);
            const PassData = response.data;
            const PassMsg = response.data?.Message;
            if (PassMsg === 'Forgot Password Initiated!') {
                setTimeout(() => {
                    enqueueSnackbar('Verification code sent!');
                }, 1000);
                setLoading(false);
            } else {
                const UserError =
                    response.data.data?.Message || response.data?.Message || response.data?.msg;
                enqueueSnackbar(UserError, { variant: 'error' });
                setTimeout(() => {
                    navigate('/authentication/auth-forgot-password');
                }, 2000);
                setLoading(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                setLoading(false);
                setTimeout(() => {
                    navigate('/authentication/auth-forgot-password');
                }, 2000);
            } else {
                enqueueSnackbar('Something Went Wrong, Please try again later!', {
                    variant: 'error',
                });
                setLoading(false);
                setTimeout(() => {
                    navigate('/authentication/auth-forgot-password');
                }, 2000);
            }
        }
    };

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            {/* <RHFTextField
                name="email"
                label="Email"
                placeholder="example@gmail.com"
                disabled = {forgotemail}
                // value={passdata.user_email}
                InputLabelProps={{ shrink: true }}
            /> */}

            <RHFCode name="code" />

            <RHFTextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:password-bold" width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                    icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <RHFTextField
                name="confirmPassword"
                label="Confirm New Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:password-bold" width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                    icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                helperText="Note: Min 8 characters, includes lowercase and uppercase letters, numbers, and special characters"
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Update Password
            </LoadingButton>

            <Typography variant="body2">
                {`Don’t have a code? `}

                <LoadingButton
                    variant="text"
                    color="primary"
                    onClick={forgotpass}
                    loading={loading}
                >
                    Resend Code
                </LoadingButton>
            </Typography>

            <Link
                component={RouterLink}
                href={paths.authentication.authlogin}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Return to sign in
            </Link>
        </Stack>
    );

    const renderHead = (
        <>
            <SentIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">Request sent successfully!</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    We&apos;ve sent a 6-digit confirmation email to your email {forgotemail}.
                    <br />
                    Please enter the code in below box to verify your email.
                </Typography>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}

            {renderForm}
        </FormProvider>
    );
}
