import * as Yup from 'yup';
import { useCallback, useState, useEffect, useMemo } from 'react';
import axiosInstance from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useSnackbar } from 'src/components/snackbar';

// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
// assets
import { EmailInboxIcon } from 'src/assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Alert,
    Button,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    IconButton,
    Tooltip,
    InputAdornment,
} from '@mui/material';

// ----------------------------------------------------------------------

export default function ModernVerifyView() {
    const [userdata, setUserData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [isentcreated, setIsEntCreated] = useState(false);
    const [isrolescreated, setIsRolesCreated] = useState(false);
    const [isuserupdated, setIsUserUpdated] = useState(false);
    const [showMfaDialog, setShowMfaDialog] = useState(false);
    const initialTime = 2 * 60;
    const [seconds, setSeconds] = useState(initialTime);

    const apiData = useMemo(() => location.state?.apiData || {}, [location.state]);

    // const userData = localStorage.getItem('userdata');

    useEffect(() => {
        setUserData(apiData);
    }, [apiData]);

    const VerifySchema = Yup.object().shape({
        code: Yup.string()
            .min(6, 'Code must be at least 6 characters')
            .required('Code is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Email must be a valid email address'),
    });

    const defaultValues = {
        code: '',
        email: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(VerifySchema),
        defaultValues,
    });

    const {
        watch,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = useCallback(
        async (data) => {
            const senduserdata = async (formData) => {
                try {
                    const apidata = {
                        user_name: userdata.username,
                        user_code: formData.code,
                        user_email: userdata.email || formData.email,
                        user_phone: userdata.phonenumber,
                        is_phone_number_verified: userdata.is_phone_number_verified,
                    };
                    console.log('apidata', apidata);
                    const response = await axiosInstance.post('/user/conf_user', apidata);
                    console.log('API response:', response.data);

                    const UserStatus = response.data.data?.Status;
                    console.log('API response UserStatus:', UserStatus);
                    if (UserStatus === true && response.data.data?.enterprise_id.length > 0) {
                        setTimeout(() => {
                            navigate('/authentication/auth-login');
                            enqueueSnackbar(response.data.data?.Message, {
                                variant: 'success',
                            });
                        }, 1000);
                    } else if (
                        UserStatus === true &&
                        response.data.data?.enterprise_id.length === 0
                    ) {
                        enqueueSnackbar(response.data?.data?.Message || response.data?.Message, {
                            variant: 'success',
                        });
                        setTimeout(() => {
                            createenterprise();
                            setShowMfaDialog(true);
                            enqueueSnackbar('Registering Enterprise Initiated', {
                                variant: 'info',
                            });
                        }, 1000);
                    } else if (UserStatus === false) {
                        const UserError = response.data.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const createenterprise = async () => {
                setLoading1(true);
                try {
                    const entdata = {
                        email: values.email,
                        service_act: 'No',
                    };
                    console.log('entdata', entdata);
                    const response = await axiosInstance.post('/ent/create_ent', entdata);
                    console.log('API response:', response.data);
                    const EntStatus = response.data?.Status;
                    if (EntStatus === true) {
                        setTimeout(() => {
                            setIsEntCreated(true);
                            setLoading1(false);
                            createroles();
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const createroles = async () => {
                setLoading2(true);
                try {
                    const roledata = {
                        email: values?.email,
                    };
                    console.log('roledata', roledata);
                    const response = await axiosInstance.post(
                        '/user/create_default_roles',
                        roledata
                    );
                    console.log('API response:', response.data);
                    const RoleStatus = response.data?.Status;
                    if (RoleStatus === true) {
                        setTimeout(() => {
                            setIsRolesCreated(true);
                            setLoading2(false);
                            updateuser();
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading2(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading2(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading2(true);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const updateuser = async () => {
                setLoading3(true);
                try {
                    const user = {
                        email: values?.email,
                    };
                    console.log('userdata', user);
                    const response = await axiosInstance.put('/user/update_user', user);
                    console.log('API response:', response.data);
                    const RoleStatus = response.data?.Status;
                    if (RoleStatus === true) {
                        setTimeout(() => {
                            setIsUserUpdated(true);
                            setLoading3(false);
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);
                await senduserdata(data);
            } catch (error) {
                console.error(error);
            }
        },
        [userdata, enqueueSnackbar, navigate, values]
    );

    const resendcode = async () => {
        try {
            // setUserData(apiData);
            const response = await axiosInstance.post('/user/resend_code', {
                user_name: userdata.username,
                user_email: values.email,
            });
            console.log('API response:', response.data);

            const message = response.data?.message;
            console.log('API response message:', message);

            if (message === 'Code Sent Successfully') {
                setTimeout(() => {
                    enqueueSnackbar('Verification Code Sent Successfully!', { variant: 'success' });
                }, 1000);
            } else {
                const UserError = response.data?.message;
                enqueueSnackbar(UserError, { variant: 'error' });
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                enqueueSnackbar(error.response.data.Message, { variant: 'error' });
            } else {
                enqueueSnackbar('Something Went Wrong, Please try again later!', {
                    variant: 'error',
                });
            }
        }
    };

    useEffect(() => {
        if (showMfaDialog) {
            const intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [showMfaDialog, seconds]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = timeInSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const isApplyButtonEnabled =
        isuserupdated === true && isrolescreated === true && isentcreated === true;

    const handleNavigateToMfa = () => {
        navigate('/authentication/auth-login');
    };

    const mfaDialog = (
        <Dialog open={showMfaDialog} backdropclick="ignore" fullWidth maxWidth="sm">
            <Stack direction="row" alignItems="center" spacing={22}>
                <DialogTitle>Completing User Registration</DialogTitle>
                <Alert severity="warning">{formatTime(seconds)}</Alert>
            </Stack>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Creating Enterprise</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isentcreated !== true ? 'error' : 'success'}
                            loading={loading1}
                        >
                            {isentcreated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Creating Roles and Permissions</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isrolescreated !== true ? 'error' : 'success'}
                            loading={loading2}
                        >
                            {isrolescreated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Updating User Details</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isuserupdated !== true ? 'error' : 'success'}
                            loading={loading3}
                        >
                            {isuserupdated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {isrolescreated === true && isentcreated === true && isuserupdated === true ? (
                    <Alert severity="success">All registration steps are done!</Alert>
                ) : (
                    <Alert severity="info">
                        Registration in progress. Please stay on this page.
                    </Alert>
                )}
                <Stack direction="row" spacing={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        onClick={handleNavigateToMfa}
                        disabled={!isApplyButtonEnabled}
                    >
                        Login
                    </Button>
                    <Tooltip
                        title='The default password to sign in is "Admin@123",Please change the password later!'
                        placement="right"
                    >
                        <IconButton color="error">
                            <Iconify icon="material-symbols:info" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </DialogActions>
        </Dialog>
    );

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFTextField
                name="email"
                label="Business Email"
                placeholder="Email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="ic:twotone-email" width={24} />
                        </InputAdornment>
                    ),
                }}
                // disabled
                InputLabelProps={{ shrink: true }}
            />

            <RHFCode name="code" />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Verify
            </LoadingButton>

            <Typography variant="body2">
                {`Didn't receive any code? `}
                <Link
                    variant="subtitle2"
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={resendcode}
                >
                    Resend code
                </Link>
            </Typography>

            <Link
                component={RouterLink}
                href={paths.authentication.authregister}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Return to sign up
            </Link>
        </Stack>
    );

    const renderHead = (
        <>
            <EmailInboxIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">Please check your email!</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    We have emailed a 6-digit confirmation code to {userdata.email}, please enter
                    the code in below box to verify your email.
                </Typography>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}
            {renderForm}
            {mfaDialog}
        </FormProvider>
    );
}
