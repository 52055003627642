import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'src/components/snackbar';
import axiosInstance from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';

export default function ModermAuthMFAView() {
    const [authData, setAuthdata] = useState([]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { loginaws } = useAuthContext();

    const loginresponse = localStorage.getItem('loginresponse');
    console.log('loginresponse', loginresponse);
    const mfaData = JSON.parse(loginresponse);
    const mfadata = mfaData?.data;

    const NewPasswordSchema = Yup.object().shape({
        code: Yup.string()
            .min(6, 'Code must be at least 6 characters')
            .required('Code is required'),
    });

    const defaultValues = {
        code: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const authmfa = async (formData) => {
                try {
                    const authmfadata = {
                        session: mfadata?.Session || mfadata.data?.Session,
                        mfa_code: formData.code,
                        user_name:
                            mfadata?.ChallengeParameters.USER_ID_FOR_SRP ||
                            mfadata.data?.ChallengeParameters.USER_ID_FOR_SRP,
                        challenge_name: mfadata?.ChallengeName || mfadata.data?.ChallengeName,
                    };

                    console.log('authmfadata', authmfadata);
                    const response = await axiosInstance.post('/user/auth_mfa', authmfadata);
                    const AuthData = response.data;
                    console.log('AuthData', AuthData);
                    const MFAMessage = response.data.data?.Message || response.data.Message;
                    // const mfamessage = response.data.data?.msg || response.data.msg;
                    console.log('mfamessage', MFAMessage);
                    if (MFAMessage === 'User Data Fetched Successfully') {
                        await loginaws(response);
                        setAuthdata(AuthData);
                        localStorage.removeItem('loginresponse');
                        navigate('/dashboard', { state: { authData } });
                        setTimeout(() => {
                            enqueueSnackbar('User Signed In Successfully!', { variant: 'success' });
                        }, 1000);
                    } else {
                        const UserError = response.data.data?.Message || response.data.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                        navigate('/authentication/auth-login');
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                        navigate('/authentication/auth-login');
                    }
                }
            };

            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);

                if (data.code) {
                    await authmfa(data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        // [loginaws,mfadata]
        [mfadata, authData, enqueueSnackbar, navigate, loginaws]
    );
    const renderHead = (
        <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography variant="h4">Multi-factor authentication</Typography>

            <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">
                    Your account is secured using multi-factor authentication (MFA). To finish
                    signing in, turn on or view your MFA device and type the authentication code
                    below.
                </Typography>
            </Stack>
        </Stack>
    );

    const renderForm = (
        <Stack spacing={2.5}>
            <RHFTextField
                name="Firstname"
                label="User Name"
                placeholder="JohnDoe"
                value={
                    mfadata?.ChallengeParameters?.USER_ID_FOR_SRP ||
                    mfadata?.data?.ChallengeParameters?.USER_ID_FOR_SRP ||
                    'Undefined'
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:user-id-bold-duotone" width={24} />
                        </InputAdornment>
                    ),
                }}
                disabled
            />

            <RHFCode name="code" />

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
            >
                Submit
            </LoadingButton>
            <Link
                component={RouterLink}
                href={paths.authentication.authlogin}
                variant="body2"
                color="inherit"
                underline="always"
                sx={{ alignSelf: 'flex-end' }}
            >
                Return to Signin?
            </Link>
        </Stack>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}

            {renderForm}
        </FormProvider>
    );
}
