import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthLayout from 'src/layouts/auth/auth-layout';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import AuthChangePasswordPage from 'src/pages/authentication/auth-change-password';
import AuthMFAPage from 'src/pages/authentication/auth-mfa';
import AuthVerifyMFAPage from 'src/pages/authentication/auth-verify-mfa';

// ----------------------------------------------------------------------

// MODERN
const AuthLoginPage = lazy(() => import('src/pages/authentication/auth-login'));
const AuthRegisterPage = lazy(() => import('src/pages/authentication/auth-register'));
const AuthForgotPasswordPage = lazy(() => import('src/pages/authentication/auth-forgot-password'));
const AuthVerifyPage = lazy(() => import('src/pages/authentication/auth-verify'));
const AuthNewPasswordPage = lazy(() => import('src/pages/authentication/auth-new-password'));

// ----------------------------------------------------------------------

export const authenticationRoutes = [
    {
        path: 'authentication',
        children: [
            {
                path: 'auth-login',
                element: (
                    <GuestGuard>
                        <AuthLayout>
                            <AuthLoginPage />
                        </AuthLayout>
                    </GuestGuard>
                ),
            },
            {
                path: 'auth-register',
                element: (
                    <GuestGuard>
                        <AuthLayout>
                            <AuthRegisterPage />
                        </AuthLayout>
                    </GuestGuard>
                ),
            },
            {
                path: 'auth-verify-mfa',
                element: (
                    <GuestGuard>
                        <AuthLayout>
                            <AuthVerifyMFAPage />
                        </AuthLayout>
                    </GuestGuard>
                ),
            },
            {
                element: (
                    <AuthModernCompactLayout>
                        <Outlet />
                    </AuthModernCompactLayout>
                ),
                children: [
                    { path: 'auth-forgot-password', element: <AuthForgotPasswordPage /> },
                    { path: 'auth-new-password', element: <AuthNewPasswordPage /> },
                    { path: 'auth-verify', element: <AuthVerifyPage /> },
                    { path: 'auth-change-password', element: <AuthChangePasswordPage /> },
                    { path: 'auth-mfa', element: <AuthMFAPage /> },
                ],
            },
        ],
    },
];
