// @mui
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect, useMemo } from 'react';
import axiosInstance from 'src/utils/axios';
import { useTheme } from '@mui/material/styles';
// import { m } from 'framer-motion';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import { LoadingScreen } from 'src/components/loading-screen';

// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useNavigate } from 'react-router-dom';
// components
import Logo from 'src/components/logo';
// import { varHover } from 'src/components/animate';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
// import Iconify from 'src/components/iconify/iconify';
import { useAuthContext } from 'src/auth/hooks';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getEnterprise } from 'src/redux/slices/select-enterprises';
//
import { HEADER, NAV } from '../config-layout';
import SelectEnterprise from '../_common/searchbar/select-enterprise';
import {
    Searchbar,
    AccountPopover,
    SettingsButton,
    // LanguagePopover,
    // ContactsPopover,
    // NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { userentcontext, userawscontext, userorgcontext } = useAuthContext();
    const [ent, setEnterprises] = useState([]);
    const [age1, setAge1] = useState({ enterprise_id: '', name: '' });
    const [loading, setloading] = useState(false);

    const navigate = useNavigate();

    const settings = useSettingsContext();

    const isNavHorizontal = settings.themeLayout === 'horizontal';

    const isNavMini = settings.themeLayout === 'mini';

    const lgUp = useResponsive('up', 'lg');

    const offset = useOffSetTop(HEADER.H_DESKTOP);

    const offsetTop = offset && !isNavHorizontal;

    const enterprise_redux = useSelector((state) => state.enterprise);

    const getEnterprises = useCallback(async () => {
        console.log('okokokook');
        const response = await axiosInstance
            .post('/ent/list_ent_with_id', {
                org_id: userorgcontext?.org_id,
            })
            .then((result) => {
                console.log('headerresponse', result.data);

                setEnterprises(result.data?.Enterprises);
            });
    }, [userorgcontext]);

    useEffect(() => {
        getEnterprises();
    }, [getEnterprises]);

    const onselectEnterprise = (selectedEnterprise) => {
        console.log('selectedEnterprise dispatch', selectedEnterprise);

        setloading(true);

        setTimeout(() => {
            dispatch(getEnterprise(selectedEnterprise));
            navigate('/dashboard');

            setloading(false);
        }, 2000);
    };

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

            {!lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}

            <Searchbar />

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                <Typography variant="body2">
                    <strong>Organisation :</strong> {userorgcontext?.org_name}
                </Typography>
                {/* {userawscontext?.user_role === 'Super Admin' ? (
                    <FormControl sx={{ width: '200px' }}>
                        <InputLabel id="enterprise-select-label">Select Enterprise</InputLabel>
                        <Select
                            labelId="enterprise-select-label"
                            id="enterprise-select"
                            sx={{ width: '100%' }}
                            label="Select Enterprise"
                            value={enterprise_redux.enterprise_name || ''}
                            onChange={(e) => {
                                const selectedEnterprise = ent.find(
                                    (enterprise) => enterprise.name === e.target.value
                                );
                                setAge1(selectedEnterprise);
                                onselectEnterprise(selectedEnterprise);
                            }}
                        >
                            {ent.map((enterprise, index) => (
                                <MenuItem key={index} value={enterprise.name}>
                                    {enterprise.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <Typography variant="body2">
                        <strong>Enterprise :</strong> {userentcontext?.name}
                    </Typography>
                )} */}

                {/* <SelectEnterprise enterprise_data={ent}/> */}
                {/* <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={handleClick}
          sx={{
            width: 40,
            height: 40,
          }}
        >
          <Iconify icon="solar:buildings-bold-duotone" width={24} />
        </IconButton> */}

                {/* <LanguagePopover /> */}

                {/* <NotificationsPopover /> */}

                {/* <ContactsPopover /> */}

                <SettingsButton />

                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}>
                <LoadingScreen />
                {/* <img src={loadinganimation} alt="loading..." /> */}
            </Backdrop>
            {/* {loading===true?<img src={loading} alt="loading..." />:''} */}
            <AppBar
                sx={{
                    height: HEADER.H_MOBILE,
                    zIndex: theme.zIndex.appBar + 2,
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                    transition: theme.transitions.create(['height'], {
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(lgUp && {
                        width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                        height: HEADER.H_DESKTOP,
                        ...(offsetTop && {
                            height: HEADER.H_DESKTOP_OFFSET,
                        }),
                        ...(isNavHorizontal && {
                            width: 1,
                            bgcolor: 'background.default',
                            height: HEADER.H_DESKTOP_OFFSET,
                            borderBottom: `dashed 1px ${theme.palette.divider}`,
                        }),
                        ...(isNavMini && {
                            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                        }),
                    }),
                }}
            >
                <Toolbar
                    sx={{
                        height: 1,
                        px: { lg: 5 },
                    }}
                >
                    {renderContent}
                </Toolbar>
            </AppBar>
        </>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
