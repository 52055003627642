import { Helmet } from 'react-helmet-async';
// sections
import { ModernMFAView } from 'src/sections/authentication';

// ----------------------------------------------------------------------

export default function AuthMFAPage() {
  return (
    <>
      <Helmet>
        <title>MFA</title>
      </Helmet>

      <ModernMFAView />
    </>
  );
}
