import { lazy } from 'react';
import { Outlet } from 'react-router';
import AuthModernWizardLayout from 'src/layouts/auth/wizard-classic';

// layouts

// Wizard component

const AuthEnterprise = lazy(() => import('src/pages/authentication/auth-enterprise'));

export const wizardRoutes = [
    {
        element: (
            <AuthModernWizardLayout>
                <Outlet />
            </AuthModernWizardLayout>
        ),
        path: 'register-android-enterprise',
        children: [
            { element: <AuthEnterprise />, index: true },
            { path: ':id', element: <AuthEnterprise /> },
        ],
    },
];
