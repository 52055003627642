import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Image from 'src/components/image';
import { MotionContainer, varFade } from 'src/components/animate';
import Carousel, { CarouselArrowIndex, useCarousel } from 'src/components/carousel';

// ----------------------------------------------------------------------

export default function CarouselAnimation({ data }) {
    const carousel = useCarousel({
        speed: 800,
        autoplay: true,
    });

    return (
        <Card sx={{ width: '100%', height: 'auto' }}>
            <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
                {data.map((item, index) => (
                    <CarouselItem
                        key={item.id}
                        item={item}
                        active={index === carousel.currentIndex}
                    />
                ))}
            </Carousel>

            <CarouselArrowIndex
                index={carousel.currentIndex}
                total={data.length}
                onNext={carousel.onNext}
                onPrev={carousel.onPrev}
            />
        </Card>
    );
}

CarouselAnimation.propTypes = {
    data: PropTypes.array,
};

// ----------------------------------------------------------------------

function CarouselItem({ item, active }) {
    const theme = useTheme();
    const [showDescription, setShowDescription] = useState(false);

    const { coverUrl, title } = item;

    const variants = theme.direction === 'rtl' ? varFade().inLeft : varFade().inRight;

    const handleToggleDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <Paper sx={{ position: 'relative' }}>
            <Image
                alt={title}
                src={coverUrl}
                ratio="4/3"
                sx={{ filter: showDescription ? 'blur(5px)' : 'none' }}
            />

            <Box
                sx={{
                    top: 0,
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    ...bgGradient({
                        direction: 'to top',
                        startColor: `${theme.palette.grey[900]} 0%`,
                        endColor: `${alpha(theme.palette.grey[900], 0)} 100%`,
                    }),
                }}
            />

            <CardContent
                component={MotionContainer}
                animate={active}
                action
                sx={{
                    left: 0,
                    maxWidth: '100%',
                    textAlign: 'center',
                    position: 'absolute',
                    color: showDescription ? 'common.white' : 'lightgray',
                    ...(showDescription && { top: 0 }),
                    ...(showDescription || { bottom: 0 }),
                    transition: 'color 0.5s ease-in-out',
                }}
            >
                <m.div variants={variants}>
                    <Typography variant="h4" mb={3} gutterBottom>
                        {item.title}
                    </Typography>
                </m.div>
                <m.div variants={variants}>
                    <Typography variant="h5" mb={3} gutterBottom>
                        {item.subtitle}
                    </Typography>
                </m.div>
                {showDescription && (
                    <m.div variants={variants}>
                        <Typography variant="body2" noWrap gutterBottom>
                            {item.description}
                        </Typography>
                    </m.div>
                )}

                <m.div variants={variants}>
                    <Button variant="contained" sx={{ mt: 3 }} onClick={handleToggleDescription}>
                        {showDescription ? 'View Less' : 'View More'}
                    </Button>
                </m.div>
            </CardContent>
        </Paper>
    );
}

CarouselItem.propTypes = {
    active: PropTypes.bool,
    item: PropTypes.object,
};
