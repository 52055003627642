import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const LogoHalf = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                width: 85,
                height: 42,
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >

            <svg width="100%" height="100%" viewBox="0 0 894 465" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M112.667 84.9347C73.4667 93.3347 43.3333 121.068 31.0667 160.135C28 170.001 28 170.801 28 232.801C28 294.801 28 295.601 31.0667 305.468C42.6667 342.401 70.2667 369.068 107.067 379.068C121.467 382.935 147.6 382.935 162.667 378.935C176.267 375.335 189.6 368.801 201.333 359.735C211.467 352.001 224.4 337.468 228 329.868C232.4 320.668 228 309.335 219.2 307.468C212.267 305.868 206.8 308.935 200.933 317.335C183.733 341.735 156.533 355.201 128.667 353.068C97.3333 350.668 70.1333 328.935 59.6 298.135L56 287.468V234.135C56 175.068 56.2667 173.201 64.4 156.135C71.3333 142.001 86.9333 126.268 100.667 119.735C106.133 117.201 114 114.268 118.267 113.468C128 111.335 149.867 112.401 158.667 115.601C174.667 121.201 190 133.201 199.6 147.068C207.2 158.401 215.067 161.468 223.467 156.535C231.867 151.468 231.867 142.001 223.333 129.468C207.867 106.801 182.267 89.7347 155.6 84.668C143.333 82.4014 124 82.4014 112.667 84.9347Z" fill={PRIMARY_DARK} />
                <path d="M335.999 160.268C297.466 169.735 267.199 199.201 255.066 239.068C252.266 248.401 252.133 251.468 251.599 309.735C251.199 364.001 251.333 371.068 253.333 374.801C256.933 381.868 267.866 384.401 273.999 379.735C280.133 374.935 279.999 376.268 280.666 312.268L281.333 251.468L284.933 241.735C291.733 223.068 304.133 208.001 320.933 197.735C330.933 191.735 341.466 188.268 355.866 186.268C370.399 184.268 373.599 182.801 376.133 176.535C378.799 170.401 377.466 165.068 372.399 160.801C368.933 157.868 367.333 157.468 357.599 157.601C351.599 157.601 341.866 158.801 335.999 160.268Z" fill={PRIMARY_MAIN} />
                <path d="M396.399 160.801C394.532 162.668 392.666 166.001 392.132 168.268C390.799 175.068 391.199 270.535 392.666 283.068C399.732 344.801 454.932 388.801 516.266 381.601C554.666 377.068 586.532 354.801 603.332 320.801C614.666 297.735 614.399 299.868 614.932 228.668L615.466 165.868L611.599 161.735C608.266 158.135 606.666 157.468 601.599 157.468C594.266 157.468 589.332 161.068 587.332 167.868C586.532 170.935 585.999 194.001 585.999 227.068C585.999 260.935 585.466 284.001 584.532 288.401C583.732 292.268 580.666 300.535 577.732 306.668C570.532 321.868 555.332 337.201 540.532 344.535C508.132 360.401 470.932 354.268 445.466 328.668C436.132 319.335 428.532 307.201 424.399 294.801C421.599 286.535 421.466 283.735 421.066 228.135C420.799 196.268 420.266 168.668 419.999 167.068C417.866 157.735 403.199 153.868 396.399 160.801Z" fill={PRIMARY_DARK} />
                <path d="M651.333 158.935C644.267 161.735 640.933 171.868 644.933 179.201C645.733 180.801 665.867 201.868 689.6 226.001L732.933 269.868L719.733 283.335C712.533 290.801 692.533 311.201 675.2 328.801C643.333 361.201 641.467 363.601 643.067 371.468C644.4 379.068 655.6 384.801 662.267 381.335C664 380.535 685.333 360.001 709.733 335.735L754 291.468L798.4 335.735C822.667 360.001 844 380.535 845.733 381.335C852.267 384.668 863.867 378.801 864.933 371.735C866.267 362.801 865.733 362.268 819.867 316.135C795.333 291.468 775.333 270.801 775.333 270.135C775.333 269.335 793.733 250.535 816.133 228.135C838.533 205.735 858.667 185.068 860.8 182.268C866 175.201 866.267 166.801 861.2 162.135C857.2 158.401 850.4 156.668 845.733 158.135C844.133 158.668 822.667 179.335 798.4 204.001L754 248.801L711.067 205.468C687.467 181.601 666.667 161.068 664.8 159.868C660.933 157.201 656.4 156.935 651.333 158.935Z" fill={PRIMARY_DARK} />
            </svg>

        </Box>
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
            {logo}
        </Link>
    );
});

LogoHalf.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default LogoHalf;
