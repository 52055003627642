import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { HeaderSimple as Header } from '../_common';

export default function OrganizationCreateLayout({ children }) {
  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          py: 10,
          display: 'flex',
          minHeight: '100vh',
          textAlign: 'center',
          px: { xs: 2, md: 0 },
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Card
          sx={{
            py: 5,
            px: 3,
            maxWidth: 900,
            width: '100%', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
            borderRadius: '12px',
            backgroundColor: 'white', 
          }}
        >
          {children}
        </Card>
      </Box>
    </>
  );
}

OrganizationCreateLayout.propTypes = {
  children: PropTypes.node,
};
