// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, List, ListItem, ListItemIcon, ListItemText, CardContent } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SecurityIcon from '@mui/icons-material/Security';
import AppsIcon from '@mui/icons-material/Apps';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import PolicyIcon from '@mui/icons-material/Policy';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PasswordIcon from '@mui/icons-material/Password';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GroupsIcon from '@mui/icons-material/Groups';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import CarouselAnimation from 'src/sections/_examples/extra/carousel-view/carousel-animation';

// ----------------------------------------------------------------------
const carouselContent = [
    {
        id: 1,
        coverUrl:
            'https://assets-global.website-files.com/64009032676f244c7bf002fd/64009032676f240f5df0119a_MDM_Blog.png',
        title: 'Welcome to CRUX Unified Endpoint Management!',
        subtitle: (
            <>
                {' '}
                <Typography variant="body1" sx={{ mb: 4 }}>
                    Thank you for choosing Crux, your ultimate solution for efficient and secure
                    Android device management. Our platform empowers you to streamline device
                    administration, enhance security protocols, and optimize productivity across
                    your Android devices.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Crux Unified Endpoint Management offers a comprehensive suite of features
                    designed to simplify device management:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SettingsRemoteIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Remote Device Management: Seamlessly manage Android devices from a centralized dashboard, no matter where you are." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SecurityIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Security Enhancements: Implement robust security measures to safeguard sensitive data and maintain compliance." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <AppsIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="App Distribution: Effortlessly deploy and manage applications across multiple devices, saving time and resources." />
                    </ListItem>
                </List>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Experience the power of efficient Android device management with Crux.
                </Typography>
                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    In addition, Crux provides advanced features such as:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <MonitorHeartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Real-time Monitoring: Monitor device activities in real-time for better insights and decision-making." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SecurityUpdateIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Automated Updates: Keep your devices up-to-date with automated firmware and software updates." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PolicyIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Custom Policies: Define custom policies to enforce security and compliance standards." />
                    </ListItem>
                </List>

                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to access a world of features that will transform how you manage and
                    secure your Android devices.
                </Typography>
            </>
        ),
    },
    {
        id: 2,
        coverUrl: 'https://plus.unsplash.com/premium_photo-1684225765349-072e1a35afc6?q=80&w=1932',
        title: 'User Management',
        subtitle: (
            <>
                {' '}
                <Typography variant="body1" sx={{ mb: 4 }}>
                    CRUX User Management provides a robust solution for organizing and managing
                    users within your organization. The system is designed to streamline user access
                    and permissions across different enterprises.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Organisation Structure:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create and configure individual enterprises with unique settings and requirements within your organization." />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create and manage users for specific enterprises." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Assign roles and permissions to users based on their responsibilities." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Granular control over user access, ensuring the right individuals have the right permissions." />
                    </ListItem>
                </List>

                <Typography variant="body4" sx={{ mb: 4 }}>
                    Role Assignment:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create predefined roles with specific sets of permissions." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Assign roles to users within each enterprise, ensuring efficient access control." />
                    </ListItem>
                </List>

                <Typography variant="body3" sx={{ mb: 4 }}>
                    Experience the simplicity and efficiency of user management with Crux.
                </Typography>
                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to transform how you organize and secure user access within your
                    organization.
                </Typography>
            </>
        ),
    },
    {
        id: 3,
        coverUrl: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?q=80',
        title: 'Device Management',
        subtitle: (
            <>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    CRUX Device Management simplifies the configuration, deployment, management, and
                    monitoring of devices across your organization. Our platform is equipped with
                    advanced features to ensure seamless device operations.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Key Features:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <PhonelinkSetupIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Multiple Provisioning Methods: Choose from a variety of provisioning methods to efficiently set up and configure devices." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PasswordIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Configure Password Complexity: Define passcode requirements such as length, complexity, and character types." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <MyLocationIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Geofencing: Implement geofencing capabilities to control device access and functionality based on geographical location." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GroupsIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Group Management: Efficiently organize and manage devices through intuitive group management functionalities." />
                    </ListItem>
                </List>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Restrictions - Network and Device Connectivity:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <NetworkWifiIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Network and Roaming: Control device connectivity by setting restrictions on network usage and roaming access." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SpeakerPhoneIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Device Connectivity: Manage device connectivity options, including Wi-Fi, cellular data, and more, to align with policies." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <BluetoothIcon fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="NFC and Bluetooth Security: Configure security settings for NFC and Bluetooth to protect against unauthorized access." />
                    </ListItem>
                </List>

                <Typography variant="body3" sx={{ mb: 4 }}>
                    Experience the power of Crux&apos;s Device Management capabilities.
                </Typography>
                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to easily configure, deploy, and monitor your devices with advanced
                    features that enhance efficiency and control.
                </Typography>
            </>
        ),
    },
];
export default function AuthLayout({ children }) {
    const upMd = useResponsive('up', 'md');

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 600,
                px: { xs: 2, md: 8 },
            }}
        >
            <Logo
                sx={{
                    mt: { xs: 2, md: 8 },
                    mb: { xs: 10, md: 8 },
                }}
            />

            <Box
                sx={{
                    py: { xs: 5, md: 0 },
                    px: { xs: 3, md: 0 },
                    boxShadow: { md: 'none' },
                    overflow: { md: 'unset' },
                }}
            >
                {children}
            </Box>
        </Stack>
    );

    // const renderSection = (
    //   <Stack flexGrow={1} sx={{ position: 'relative' }}>
    //     <Box
    // component="img"
    // alt="auth"
    // src={image || '/assets/background/overlay_3.jpg'}
    // sx={{
    //   top: 16,
    //   left: 16,
    //   objectFit: 'cover',
    //   position: 'absolute',
    //   width: 'calc(100% - 32px)',
    //   height: 'calc(100% - 32px)',
    // }}
    //     />
    //   </Stack>
    // );

    const renderSection = (
        <CardContent
            sx={{
                width: 950,
            }}
        >
            <CarouselAnimation data={carouselContent} />
        </CardContent>
    );

    return (
        <Stack direction="column">
            <Stack
                component="main"
                direction="row"
                sx={{
                    minHeight: '100vh',
                    position: 'relative',
                    '&:before': {
                        width: 1,
                        height: 1,
                        zIndex: -1,
                        content: "''",
                        position: 'absolute',
                        backgroundSize: 'cover',
                        opacity: { xs: 0.24, md: 0 },
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: 'url(/assets/background/overlay_4.jpg)',
                    },
                }}
            >
                {renderContent}

                {upMd && renderSection}
            </Stack>
            <Typography variant="caption" sx={{ textAlign: 'center', alignItems: 'center', mb: 2 }}>
                © Copyright 2023 CRUX - All Rights Reserved
            </Typography>
        </Stack>
    );
}

AuthLayout.propTypes = {
    children: PropTypes.node,
};
