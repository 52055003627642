import { Helmet } from 'react-helmet-async';
// sections
import { ModernAuthMFAView } from 'src/sections/authentication';

// ----------------------------------------------------------------------

export default function AuthVerifyMFAPage() {
  return (
    <>
      <Helmet>
        <title>MFA Authentication</title>
      </Helmet>

      <ModernAuthMFAView />
    </>
  );
}
