import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import axiosInstance from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { useSnackbar } from 'src/components/snackbar';
import {
    Alert,
    Button,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
} from '@mui/material';

// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// assets
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import PasswordIcon from 'src/assets/icons/password-icon';

// ----------------------------------------------------------------------

export default function ModernChangePasswordView() {
    const password = useBoolean();
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [isentcreated, setIsEntCreated] = useState(false);
    const [isrolescreated, setIsRolesCreated] = useState(false);
    const [isuserupdated, setIsUserUpdated] = useState(false);
    const [showMfaDialog, setShowMfaDialog] = useState(false);
    const initialTime = 2 * 60;
    const [seconds, setSeconds] = useState(initialTime);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const data1 = localStorage.getItem('logindata');
    const logindata = JSON.parse(data1);
    const data2 = localStorage.getItem('loginresponse');
    const loginresponse = JSON.parse(data2);
    console.log('logindata', logindata?.user_email);
    console.log('data1', data1);

    const NewPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    const defaultValues = {
        code: '',
        password: '',
        confirmPassword: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const updatepassword = async () => {
                try {
                    const apidata = {
                        session: loginresponse.data?.Session,
                        password: data.password,
                        user_email: logindata?.user_email,
                    };
                    const response = await axiosInstance.post('/user/new_password', apidata);
                    const UserStatus = response.data?.Status || response.data?.status;
                    console.log('API response', response.data);
                    console.log('API response UserStatus:', UserStatus);

                    if (UserStatus === true) {
                        if (
                            response.data?.enterprise_id &&
                            response.data.enterprise_id.length === 0
                        ) {
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                            setTimeout(() => {
                                createenterprise();
                                setShowMfaDialog(true);
                                enqueueSnackbar('Registering Enterprise Initiated', {
                                    variant: 'info',
                                });
                            }, 1000);
                        } else if (
                            response.data?.enterprise_id &&
                            response.data.enterprise_id.length > 0
                        ) {
                            setTimeout(() => {
                                navigate('/authentication/auth-login');
                                enqueueSnackbar(response.data?.Message, {
                                    variant: 'success',
                                });
                            }, 1000);
                        }
                    } else {
                        const UserError = response.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const createenterprise = async () => {
                setLoading1(true);
                try {
                    const entdata = {
                        email: logindata?.user_email,
                        service_act: 'No',
                        kyc_required: true,
                    };
                    console.log('entdata', entdata);
                    const response = await axiosInstance.post('/ent/create_ent', entdata);
                    console.log('API response:', response.data);
                    const EntStatus = response.data?.Status;
                    if (EntStatus === true) {
                        setTimeout(() => {
                            setIsEntCreated(true);
                            setLoading1(false);
                            createroles();
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading1(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const createroles = async () => {
                setLoading2(true);
                try {
                    const roledata = {
                        email: logindata?.user_email,
                    };
                    console.log('roledata', roledata);
                    const response = await axiosInstance.post(
                        '/user/create_default_roles',
                        roledata
                    );
                    console.log('API response:', response.data);
                    const RoleStatus = response.data?.Status;
                    if (RoleStatus === true) {
                        setTimeout(() => {
                            setIsRolesCreated(true);
                            setLoading2(false);
                            updateuser();
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading2(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading2(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading2(true);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            const updateuser = async () => {
                setLoading3(true);
                try {
                    const user = {
                        email: logindata?.user_email,
                    };
                    console.log('userdata', user);
                    const response = await axiosInstance.put('/user/update_user', user);
                    console.log('API response:', response.data);
                    const RoleStatus = response.data?.Status;
                    if (RoleStatus === true) {
                        setTimeout(() => {
                            setIsUserUpdated(true);
                            setLoading3(false);
                            enqueueSnackbar(response.data?.Message, {
                                variant: 'success',
                            });
                        }, 500);
                    } else {
                        const UserError = response.data?.Message;
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        setLoading3(false);
                        setShowMfaDialog(false);
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);
                await updatepassword(data);
            } catch (error) {
                console.error(error);
            }
        },
        [enqueueSnackbar, navigate, loginresponse, logindata]
    );

    useEffect(() => {
        if (showMfaDialog) {
            const intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [showMfaDialog, seconds]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = timeInSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const isApplyButtonEnabled =
        isuserupdated === true && isrolescreated === true && isentcreated === true;

    const handleNavigateToMfa = () => {
        navigate('/authentication/auth-login');
        enqueueSnackbar('User registration Done!', {
            variant: 'success',
        });
    };

    const mfaDialog = (
        <Dialog open={showMfaDialog} backdropclick="ignore" fullWidth maxWidth="sm">
            <Stack direction="row" alignItems="center" spacing={22}>
                <DialogTitle>Completing User Registration</DialogTitle>
                <Alert severity="warning">{formatTime(seconds)}</Alert>
            </Stack>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Creating Enterprise</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isentcreated !== true ? 'error' : 'success'}
                            loading={loading1}
                        >
                            {isentcreated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Creating Roles and Permissions</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isrolescreated !== true ? 'error' : 'success'}
                            loading={loading2}
                        >
                            {isrolescreated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">Updating User Details</Typography>
                        <LoadingButton
                            variant="contained"
                            color={isuserupdated !== true ? 'error' : 'success'}
                            loading={loading3}
                        >
                            {isuserupdated !== true ? 'Incomplete' : 'Completed'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {isrolescreated === true && isentcreated === true && isuserupdated === true ? (
                    <Alert severity="success">All registration steps are done!</Alert>
                ) : (
                    <Alert severity="info">
                        Registration in progress. Please stay on this page.
                    </Alert>
                )}

                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    onClick={handleNavigateToMfa}
                    disabled={!isApplyButtonEnabled}
                >
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    );
    const renderForm = (
        <Stack spacing={3} alignItems="center">
            {/* <RHFTextField
                name="email"
                label="Business Email"
                placeholder="Email"
                InputLabelProps={{ shrink: true }}
            /> */}

            <RHFTextField
                name="password"
                label="New Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:password-bold" width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                    icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                helperText="Note: Min 8 characters, includes lowercase and uppercase letters, numbers, and special characters"
            />

            <RHFTextField
                name="confirmPassword"
                label="Confirm New Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:password-bold" width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                    icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                helperText="Note: Min 8 characters, includes lowercase and uppercase letters, numbers, and special characters"
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Create Password
            </LoadingButton>

            <Link
                component={RouterLink}
                href={paths.authentication.authlogin}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Return to sign in
            </Link>
        </Stack>
    );

    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 90 }} />

            <Stack spacing={1} sx={{ my: 2 }}>
                <Typography variant="h3">Create New Password!</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Please enter a new password for your email {logindata?.user_email}
                </Typography>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}
            {renderForm}
            {mfaDialog}
        </FormProvider>
    );
}
