import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';

import { RouterLink } from 'src/routes/components';
// assets
import { PasswordIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import axiosInstance from 'src/utils/axios';
import { InputAdornment } from '@mui/material';

// ----------------------------------------------------------------------

export default function ModernForgotPasswordView() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const ResetPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email must be a valid email address'),
    });

    const userData = localStorage.getItem('userdata');

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(ResetPasswordSchema),
        defaultValues: {
            email: userData?.email || '',
        },
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const forgotpass = async (formData) => {
                try {
                    const passdata = {
                        user_email: formData.email,
                    };
                    console.log('passdata', passdata);
                    localStorage.setItem('forgotemail', formData.email);
                    const response = await axiosInstance.post('/user/forgot_pass', passdata);
                    console.log('API response:', response.data);
                    const PassMsg = response.data?.Message;
                    if (PassMsg === 'Forgot Password Initiated!') {
                        navigate('/authentication/auth-new-password');
                        setTimeout(() => {
                            enqueueSnackbar('Forgot Password Initiated!');
                        }, 1000);
                    } else {
                        const UserError =
                            response.data.data?.Message ||
                            response.data?.Message ||
                            response.data?.msg;
                        enqueueSnackbar(UserError, { variant: 'error' });
                        setTimeout(() => {
                            navigate('/authentication/auth-login');
                        }, 2000);
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                        setTimeout(() => {
                            navigate('/authentication/auth-login');
                        }, 2000);
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                        setTimeout(() => {
                            navigate('/authentication/auth-login');
                        }, 2000);
                    }
                }
            };
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);
                if (data.email) {
                    await forgotpass(data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        [navigate, enqueueSnackbar]
    );

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFTextField
                name="email"
                label="Email address"
                placeholder="example@gmail.com"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="ic:twotone-email" width={24} />
                        </InputAdornment>
                    ),
                }}
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
            >
                Send Request
            </LoadingButton>

            <Link
                component={RouterLink}
                href={paths.authentication.authlogin}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Return to sign in
            </Link>
        </Stack>
    );

    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">Forgot your password?</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Please enter the email address associated with your account and We will email
                    you a link to reset your password.
                </Typography>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}

            {renderForm}
        </FormProvider>
    );
}
