import PropTypes from 'prop-types';
import { memo, useState, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, List, Stack, Collapse } from '@mui/material';
//
import Iconify from 'src/components/iconify/iconify';
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';

import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          // icon={group.icon}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
}

NavSectionVertical.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

function Group({ subheader, items, 
  // icon,
   config }) {
  const [open, setOpen] = useState(true); // sandeep edit
  const theme = useTheme();

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = items.map((list) => (
    list?.permission===false ? (
      <></>
    ) :
   ( <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
    />)
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          {/* sandeep edit  */}
          <StyledSubheader disableGutters disableSticky onClick={handleToggle} config={config}>
            {/* <Iconify icon={icon}
              sx={{
                mr: 2,
                color: theme.palette.primary.main,
              }} /> */}
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
  subheader: PropTypes.string,
  // icon: PropTypes.string,
};
