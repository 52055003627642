import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// CONTEXT API
import { useAuthContext } from 'src/auth/hooks';


// ----------------------------------------------------------------------



    // const { userorgcontext, userentcontext } = useAuthContext();



const initialState = {
    enterprise_id:"",
    enterprise_name:""
   
  };

const slice=createSlice({
    name:'enterprise',
    initialState,
    reducers:{
        getEnterprise(state,action){
            const {name,enterprise_id}=action.payload
            state.enterprise_id=enterprise_id
            state.enterprise_name=name
        },
        setEnterprise(state,action){
            const {ent_id,name}=action.payload
            state.enterprise_id=ent_id
            state.enterprise_name=name
        }
    }
})

export default slice.reducer;
export const {getEnterprise,setEnterprise}= slice.actions;