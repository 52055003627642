import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from 'src/utils/axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'src/components/snackbar';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';

// ----------------------------------------------------------------------

export default function ModernLoginView() {
    const [userlogindata, setUserLoginData] = useState([]);
    const [showMfaDialog, setShowMfaDialog] = useState(false);
    const [mfadata, setMfadata] = useState([]);
    const [loading, setLoading] = useState(false);
    const { loginaws1 } = useAuthContext();

    const password = useBoolean();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const apiData1 = location.state?.UserStatus || {};
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .required('Email is required')
            .email('Email must be a valid email address'),
        password: Yup.string().required('Password is required'),
    });

    console.log('usermfadata', mfadata);

    // const data1 = localStorage.getItem('userdata');
    // const userdata = JSON.parse(data1);
    // console.log('userData', data1);

    const defaultValues = {
        username: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            const logindata = async (formData) => {
                try {
                    const apidata = {
                        password: formData.password,
                        user_email: formData.username,
                    };
                    console.log('apidata', apidata);
                    localStorage.setItem('logindata', JSON.stringify(apidata));
                    const response = await axiosInstance.post('/user/sigin_user', apidata);
                    console.log('API response:', response.data);
                    const loginData = response.data || response.data?.data;
                    setUserLoginData(response.data?.data);
                    localStorage.setItem('loginresponse', JSON.stringify(loginData));
                    const UserStatus = response.data?.msg;
                    console.log('API response UserStatus:', UserStatus);

                    if (
                        (response.data.data?.ChallengeName === 'MFA_SETUP' ||
                            response.data?.data?.ChallengeName === 'SELECT_MFA_TYPE') &&
                        response.data?.data?.mfa_enabled === true &&
                        response.data?.data?.mfa_types === null
                    ) {
                        setMfadata(response.data || response.data?.data);
                        setShowMfaDialog(true);
                        setTimeout(() => {
                            enqueueSnackbar(UserStatus, { variant: 'info' });
                        }, 1000);
                    } else if (
                        (response.data?.data?.ChallengeName || response.data?.ChallengeName) ===
                        'NEW_PASSWORD_REQUIRED'
                    ) {
                        navigate('/authentication/auth-change-password', {
                            state: { apidata, loginData },
                        });
                        setTimeout(() => {
                            enqueueSnackbar('New Password Change Initiated!', { variant: 'info' });
                        }, 1000);
                        setShowMfaDialog(false);
                    } else if (
                        (response.data.data?.ChallengeName || response.data?.ChallengeName) ===
                            'SOFTWARE_TOKEN_MFA' &&
                        response.data?.data?.mfa_enabled === true &&
                        response.data?.data?.mfa_types === 'authapps'
                    ) {
                        navigate('/authentication/auth-verify-mfa', {
                            state: { loginData, apidata },
                        });
                        setTimeout(() => {
                            enqueueSnackbar('MFA Authentication Initiated!', { variant: 'info' });
                        }, 1000);
                        setShowMfaDialog(false);
                    } else if (
                        (response.data.data?.ChallengeName || response.data?.ChallengeName) ===
                            'SMS_MFA' &&
                        response.data?.data?.mfa_enabled === true &&
                        (response.data?.data?.mfa_types === 'authapps' ||
                            response.data?.data?.mfa_types === 'sms')
                    ) {
                        navigate('/authentication/auth-verify-mfa', {
                            state: { loginData, apidata },
                        });
                        setTimeout(() => {
                            enqueueSnackbar('SMS Authentication Initiated!', { variant: 'info' });
                        }, 1000);
                        setShowMfaDialog(false);
                    } else if (response.data?.Message === 'User Data Fetched Successfully') {
                        await loginaws1(response);

                        if (response.data.ent_id && response.data.ent_id.length > 0) {
                            navigate('/dashboard', { state: { loginData, apidata } });
                            setTimeout(() => {
                                enqueueSnackbar('User Signed In Successfully!', {
                                    variant: 'success',
                                });
                            }, 1000);
                        } else {
                            navigate('/dashboard/uem-setting/enterprises/list');
                            setTimeout(() => {
                                enqueueSnackbar('User Signed In Successfully to Enterprise List!', {
                                    variant: 'success',
                                });
                            }, 1000);
                            setTimeout(() => {
                                enqueueSnackbar('Complete the Enterprise Registration Process', {
                                    variant: 'info',
                                });
                            }, 4000);
                        }

                        setShowMfaDialog(false);
                    } else {
                        const UserError = response.data?.Message;
                        enqueueSnackbar(UserError, { variant: 'error' });
                    }
                } catch (error) {
                    console.error('API error:', error);
                    if (error.response && error.response.data && error.response.data.data.Message) {
                        enqueueSnackbar(error.response.data.Message, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Something Went Wrong, Please try again later!', {
                            variant: 'error',
                        });
                    }
                }
            };

            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                console.info('DATA', data);

                if (data.username && data.password) {
                    await logindata(data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        [navigate, enqueueSnackbar, loginaws1]
    );

    const authmfa = async () => {
        setLoading(true);
        try {
            const authmfadata = {
                access_token:
                    (mfadata?.data?.AuthenticationResult &&
                        mfadata.data.AuthenticationResult.AccessToken) ||
                    (mfadata?.AuthenticationResult && mfadata.AuthenticationResult.AccessToken),
                session: mfadata?.data?.Session || mfadata?.Session,
                mfa_type: userlogindata?.org_mfa,
            };

            console.log('authmfadata', authmfadata);
            if (authmfadata.mfa_type === 'sms') {
                navigate('/authentication/auth-verify-mfa', {
                    state: { mfadata, authmfadata },
                });
                setTimeout(() => {
                    enqueueSnackbar('SMS Verification Initiated!', { variant: 'info' });
                }, 1000);
            } else {
                const response = await axiosInstance.post('/user/mfa_assoc', authmfadata);
                console.log('API response:', response.data);
                const AuthData = response.data;
                console.log('AuthData', AuthData);
                const MFAStatus = response.data.message;
                if (MFAStatus === 'Token validation successful') {
                    navigate('/authentication/auth-mfa', {
                        state: { AuthData, mfadata, authmfadata },
                    });
                    setTimeout(() => {
                        enqueueSnackbar('MFA Verification Initiated!', { variant: 'info' });
                    }, 1000);
                } else {
                    const UserError = response.data?.Message;
                    enqueueSnackbar(UserError, { variant: 'error' });
                }
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                enqueueSnackbar(error.response.data.Message, { variant: 'error' });
            } else {
                enqueueSnackbar('Something Went Wrong, Please try again later!', {
                    variant: 'error',
                });
            }
        }
        setLoading(false);
    };

    const renderAlert =
        apiData1 === 'FORCE_CHANGE_PASSWORD' ? (
            <Alert
                severity="info"
                sx={{
                    backgroundColor: '#2196F3',
                    color: '#FFFFFF',
                    borderRadius: '4px',
                    padding: '10px',
                    mb: 3,
                }}
            >
                <AlertTitle style={{ fontWeight: 'bold' }}>Info</AlertTitle>
                Please enter the temporary password that has been sent to your email account
            </Alert>
        ) : null;

    const mfaDialog = (
        <Dialog
            open={showMfaDialog}
            onClose={() => setShowMfaDialog(false)}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Multi-Factor Authentication</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Your organization has mandated MFA authentication for all users. Please
                            proceed to set up MFA for your account.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowMfaDialog(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={authmfa}
                >
                    Initiate
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );

    const renderHead = (
        <Stack spacing={2} sx={{ mb: 5, p: 1.5 }}>
            <Typography variant="h4">Sign in to CRUX</Typography>

            <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">New user?</Typography>

                <Link
                    component={RouterLink}
                    href={paths.authentication.authregister}
                    variant="subtitle2"
                >
                    Create an account
                </Link>
            </Stack>
        </Stack>
    );

    const renderForm = (
        <Stack spacing={2.5} sx={{ p: 1.5 }}>
            <RHFTextField
                name="username"
                label="Email address"
                type="email"
                autofill="enabled"
                InputLabelProps={{ shrink: true }}
                autocomplete="on"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="mdi:email" width={24} />
                        </InputAdornment>
                    ),
                }}
            />

            <RHFTextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:password-bold" width={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                    icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                autofill="enabled"
                autocomplete="on"
                // helperText="Note: Min 8 characters, includes lowercase and uppercase letters, numbers, and special characters"
            />

            <Link
                component={RouterLink}
                href={paths.authentication.authforgotPassword}
                variant="body2"
                color="inherit"
                underline="always"
                sx={{ alignSelf: 'flex-end' }}
            >
                Forgot password?
            </Link>

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
            >
                Login
            </LoadingButton>
        </Stack>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderAlert}
            {mfaDialog}
            {renderHead}

            {renderForm}
        </FormProvider>
    );
}
