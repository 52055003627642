import { Helmet } from 'react-helmet-async';
// sections
import { ModernChangePasswordView } from 'src/sections/authentication';

// ----------------------------------------------------------------------

export default function AuthChangePasswordPage() {
  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <ModernChangePasswordView />
    </>
  );
}
