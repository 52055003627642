// @mui
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Button, CardHeader, Card, CardContent } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------
const sliderdata = [
  {
    logo: "mdi:security-lock",
    title: "Enhanced sign-in security",
    description: "Using only a password to sign in? To strengthen the security of your account, we're introducing additional verification for any unusual sign-in attempt.",
  },
  {
    logo: "mdi:shield-key",
    title: "MFA for all accounts",
    description: "Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.",
  },
  {
    logo: "mdi:password",
    title: "Passwordless sign-in",
    description: "Move away from risky passwords and experience one-tap access to Zoho. Download and install OneAuth.",
  },
]


export default function AuthClassicLayout({ children, image, title }) {

  const { method } = useAuthContext();

  const theme = useTheme();

  const carousel = useCarousel({
    autoplay: true,
    ...CarouselDots({
      rounded: true,
      sx: { mt: 3 },
    }),
  });

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        maxWidth: 450,
      }}
    >
      {children}
    </Stack>
  );

  const sliderdesign = (
    <Box
      sx={{
        width: 420,
        m: 'auto',
        display: { xs: 'none', md: 'inline-block' }
      }}>

      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {sliderdata.map((item) => (
          <Box
            sx={{
              pl: 1,
              pr: 5,
              pt: 6,
              pb: 8,
              width: "100%",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}>
            <Iconify icon={item.logo} sx={{ width: 160, height: 160 }} />
            <Typography variant='subtitle1' gutterBottom>{item.title}</Typography>
            <Typography variant='body1' gutterBottom>{item.description}</Typography>
            <Button variant="text">Learn More</Button>
          </Box>
        ))}
      </Carousel>
    </Box>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >

      <Stack direction="row"
        sx={{
          boxShadow: { xs: 'none', md: "0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.1)" },
          borderRadius: "10px",
          justifyContent: "center",

        }}>
        {renderContent}
        {sliderdesign}
      </Stack>
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
